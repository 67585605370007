<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>パネルシートヘッダー</template>
              <template v-slot:body>パネルシートボディー</template>
              <template v-slot:footer>パネルシートフッター</template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board">
              <template v-slot:headline>パネルボードヘッダー</template>
              <template v-slot:body>パネルボードボディー</template>
              <template v-slot:footer>パネルボードフッター</template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" color="blue" :link="true" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay url="/user" :link="true">
                  <template v-slot:title>タイトル</template>
                  <template v-slot:num>{{ commaSample01 | comma }}</template>
                  <template v-slot:sup>円</template>
                  <template v-slot:sub>{{ 10000 | comma }}件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
          <div class="col">
            <Panel type="board" color="green" :link="true" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay url="/user" :link="true">
                  <template v-slot:title>タイトル</template>
                  <template v-slot:num>{{ commaSample01 | comma }}</template>
                  <template v-slot:sup>円</template>
                  <template v-slot:sub>{{ 10000 | comma }}件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
          <div class="col">
            <Panel type="board" color="red" :link="true" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay url="/user" :link="true">
                  <template v-slot:title>タイトル</template>
                  <template v-slot:num>{{ commaSample01 | comma }}</template>
                  <template v-slot:sup>円</template>
                  <template v-slot:sub>{{ 10000 | comma }}件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
          <div class="col">
            <Panel type="board" color="yellow" :link="true" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay url="/user" :link="true">
                  <template v-slot:title>タイトル</template>
                  <template v-slot:num>{{ commaSample01 | comma }}</template>
                  <template v-slot:sup>円</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay>
                  <template v-slot:title>タイトル</template>
                  <template v-slot:num>{{ commaSample01 | comma }}</template>
                  <template v-slot:sup>円</template>
                  <template v-slot:sub><span class="numDisplay-sub-sup">登録件数</span>{{ 10000 | comma }}件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
          <div class="col">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay>
                  <template v-slot:title>タイトル</template>
                  <template v-slot:num>{{ 790 | comma }}</template>
                  <template v-slot:sup>人/{{ 10000 | comma }}人</template>
                  <template v-slot:sub>{{ 10000 | comma }}件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board">
              <template v-slot:headline>グラフ1</template>
              <template v-slot:headerContent><Tooltip tooltipMsg="これはツールチップです"/></template>
              <template v-slot:body>
                <ChartDoughnut class="chart-pie" :chartData="chart01" :options="chart01options" />
              </template>
            </Panel>
          </div>
          <div class="col">
            <Panel type="board">
              <template v-slot:headline>グラフ2</template>
              <template v-slot:headerContent><Tooltip tooltipMsg="This is tooltip"/></template>
              <template v-slot:body>
                <ChartDoughnut class="chart-pie" :chartData="chart02" :options="chart02options" />
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <FormRow>
                      <template v-slot:label>店舗名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-icon form-icon-search">
                            <input class="form-control form-icon-input" type="text" name="" />
                          </div>
                        </div>
                      </template>
                    </FormRow>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>client master</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="">
                                  <option value="">選択してください</option>
                                  <option value="">1</option>
                                  <option value="">2</option>
                                  <option value="">3</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>市区町村</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="">
                                  <option value="">選択してください</option>
                                  <option value="">1</option>
                                  <option value="">2</option>
                                  <option value="">3</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>タイトル</template>
                  <template v-slot:num>{{ 790 | comma }}</template>
                  <template v-slot:sup>人/{{ 10000 | comma }}人</template>
                  <template v-slot:sub>{{ 10000 | comma }}件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:headline>テーブル</template>
              <template v-slot:headerContent><a class="btn btn-sm btn-black pos-end" href="">詳細を見る</a></template>
              <template v-slot:body>
                <div class="scrollX">
                  <table class="dataTable">
                    <thead>
                      <tr>
                        <th>お名前</th>
                        <th>電話番号</th>
                        <th>メールアドレス</th>
                        <th>登録日時</th>
                        <th>利用状況</th>
                        <th>アクション</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="data in tableSample" :key="data.index">
                        <td>{{ data.name }}</td>
                        <td>{{ data.tel }}</td>
                        <td>{{ data.mail }}</td>
                        <td>{{ data.date }}</td>
                        <td>
                          <label class="form-toggle">
                            <input class="form-toggle-input" type="checkbox" value="" name="" v-model="data.checked" />
                            <span class="form-toggle-set">
                              <span class="form-toggle-bg"></span>
                              <span class="form-toggle-circle"></span>
                            </span>
                          </label>
                        </td>
                        <td>
                          <a class="btn btn-sm btn-w100 btn-bd-main" href="">編集</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template v-slot:footer>
                <div class="dataTable-footer justify-content-end">
                  <div class="form-select dataTable-footer-item dataTable-select">
                    <select class="form-control form-select-input" name="">
                      <option value="">10件</option>
                      <option value="">20件</option>
                      <option value="">30件</option>
                      <option value="">50件</option>
                    </select>
                  </div>
                  <p class="dataTable-footer-item dataTable-all">全7件</p>
                  <p class="dataTable-footer-item dataTable-display">1-7</p>
                  <div class="dataTable-footer-item dataTable-nav">
                    <button class="dataTable-nav-btn" type="button"><i class="aikon aikon-chevron_left dataTable-nav-icon"></i></button>
                    <button class="dataTable-nav-btn" type="button"><i class="aikon aikon-chevron_right dataTable-nav-icon"></i></button>
                  </div>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board">
              <template v-slot:headline>モーダル</template>
              <template v-slot:body>
                <button class="btn btn-main" type="button" v-on:click="showModal">モーダルを開く</button>
                <Modal @close="closeModal" v-if="modal">
                  <template v-slot:headline>
                    Modal
                  </template>
                  <template v-slot:body>
                    <div class="form">
                      <div class="form-section">
                        <p class="form-headline">テキスト入力</p>
                        <div class="form-row">
                          <p class="form-label">INPUT</p>
                          <div class="form-content">
                            <div class="form-content-row">
                              <div class="row">
                                <div class="col">
                                  <input class="form-control" type="text" name="" placeholder="placeholder" />
                                </div>
                                <div class="col">
                                  <input class="form-control" type="text" name="" placeholder="placeholder" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:footer>
                    <ul class="listGrid justify-content-end">
                      <li class="listGrid-item">
                        <button class="btn btn-white" type="button" @click="closeModal">キャンセル</button>
                      </li>
                      <li class="listGrid-item">
                        <button class="btn btn-main" type="button">送信</button>
                      </li>
                    </ul>
                  </template>
                </Modal>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>フォーム部品</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <p class="form-headline">テキスト入力</p>
                    <!--[input]-->
                    <FormRow>
                      <template v-slot:label :required="true">INPUT</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input class="form-control" type="text" name="" placeholder="placeholder" />
                        </div>
                        <div class="form-content-row">
                          <input class="form-control" type="text" name="" disabled placeholder="disabled" />
                        </div>
                        <div class="form-content-row">
                          <input class="form-control" type="text" name="" readonly placeholder="readonly" />
                        </div>
                      </template>
                    </FormRow>
                    <!--[textarea]-->
                    <FormRow>
                      <template v-slot:label>TEXTAREA</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <textarea class="form-control form-textarea" name="" placeholder="placeholder"></textarea>
                        </div>
                        <div class="form-content-row">
                          <textarea class="form-control form-textarea" name="" disabled placeholder="disabled"></textarea>
                        </div>
                        <div class="form-content-row">
                          <textarea class="form-control form-textarea" name="" readonly placeholder="readonly"></textarea>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                  <section class="form-section">
                    <p class="form-headline">ラジオ</p>
                    <!--[radio]-->
                    <FormRow>
                      <template v-slot:label>RADIO</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGrid">
                            <li class="listGrid-item">
                              <label class="form-radio">
                                <input class="form-radio-input" type="radio" value="" name="" />
                                <span class="form-radio-label">未チェック</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-radio">
                                <input class="form-radio-input" type="radio" value="" name="" checked />
                                <span class="form-radio-label">チェック済み</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-radio">
                                <input class="form-radio-input" type="radio" value="" name="" disabled />
                                <span class="form-radio-label">未チェック</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-radio">
                                <input class="form-radio-input" type="radio" value="" name="" checked disabled />
                                <span class="form-radio-label">チェック済み</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                    <!--[radio--button]-->
                    <FormRow>
                      <template v-slot:label>RADIO--BUTTON</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGrid">
                            <li class="listGrid-item">
                              <label class="form-radio form-radio-btn">
                                <input class="form-radio-input" type="radio" value="" name="" />
                                <span class="form-radio-label">未チェック</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-radio form-radio-btn">
                                <input class="form-radio-input" type="radio" value="" name="" checked />
                                <span class="form-radio-label">チェック済み</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-radio form-radio-btn">
                                <input class="form-radio-input" type="radio" value="" name="" disabled />
                                <span class="form-radio-label">未チェック</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-radio form-radio-btn">
                                <input class="form-radio-input" type="radio" value="" name="" checked disabled />
                                <span class="form-radio-label">チェック済み</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                  <section class="form-section">
                    <p class="form-headline">チェックボックス</p>
                    <!--[checkbox]-->
                    <FormRow>
                      <template v-slot:label>CHECKBOX</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGrid">
                            <li class="listGrid-item">
                              <label class="form-check">
                                <input class="form-check-input" type="checkbox" value="" name="" />
                                <span class="form-check-label">未チェック</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-check">
                                <input class="form-check-input" type="checkbox" value="" name="" checked />
                                <span class="form-check-label">チェック済み</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-radio">
                                <input class="form-check-input" type="checkbox" value="" name="" disabled />
                                <span class="form-check-label">未チェック</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-check">
                                <input class="form-check-input" type="checkbox" value="" name="" checked disabled />
                                <span class="form-check-label">チェック済み</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                    <!--[checkbox]-->
                    <FormRow>
                      <template v-slot:label>CHECKBOX--BUTTON</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGrid">
                            <li class="listGrid-item">
                              <label class="form-check form-check-btn">
                                <input class="form-check-input" type="checkbox" value="" name="" />
                                <span class="form-check-label">未チェック</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-check form-check-btn">
                                <input class="form-check-input" type="checkbox" value="" name="" checked />
                                <span class="form-check-label">チェック済み</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-check form-check-btn">
                                <input class="form-check-input" type="checkbox" value="" name="" disabled />
                                <span class="form-check-label">未チェック</span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-check form-check-btn">
                                <input class="form-check-input" type="checkbox" value="" name="" checked disabled />
                                <span class="form-check-label">チェック済み</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                  <section class="form-section">
                    <p class="form-headline">ファイルアップロード</p>
                    <FormRow>
                      <template v-slot:label>FILE UPLOAD</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="fileUpload">
                            <li class="fileUpload-item">
                              <Fileupload />
                            </li>
                            <li class="fileUpload-item">
                              <Fileupload />
                            </li>
                            <li class="fileUpload-item">
                              <Fileupload :disabled="true" />
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                  <section class="form-section">
                    <p class="form-headline">その他</p>
                    <!--[toggle switch]-->
                    <FormRow>
                      <template v-slot:label>TOGGLE SWITCH</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGrid">
                            <li class="listGrid-item">
                              <label class="form-toggle">
                                <input class="form-toggle-input" type="checkbox" value="" name="" />
                                <span class="form-toggle-set">
                                  <span class="form-toggle-bg"></span>
                                  <span class="form-toggle-circle"></span>
                                </span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-toggle">
                                <input class="form-toggle-input" type="checkbox" value="" name="" checked />
                                <span class="form-toggle-set">
                                  <span class="form-toggle-bg"></span>
                                  <span class="form-toggle-circle"></span>
                                </span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-toggle">
                                <input class="form-toggle-input" type="checkbox" value="" name="" disabled />
                                <span class="form-toggle-set">
                                  <span class="form-toggle-bg"></span>
                                  <span class="form-toggle-circle"></span>
                                </span>
                              </label>
                            </li>
                            <li class="listGrid-item">
                              <label class="form-toggle">
                                <input class="form-toggle-input" type="checkbox" value="" name="" checked disabled />
                                <span class="form-toggle-set">
                                  <span class="form-toggle-bg"></span>
                                  <span class="form-toggle-circle"></span>
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                    <!--[select]-->
                    <FormRow>
                      <template v-slot:label>SELECT</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-select">
                            <select class="form-control form-select-input" name="">
                              <option value="">選択してください</option>
                              <option value="">1</option>
                              <option value="">2</option>
                              <option value="">3</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-content-row">
                          <div class="form-select">
                            <select class="form-control form-select-input" name="" disabled>
                              <option value="">disabled</option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <!--[segmented control]-->
                    <FormRow>
                      <template v-slot:label>SEGMENTED CONTROL</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="form-segment">
                            <li class="form-segment-item">
                              <label class="form-segment-set">
                                <input class="form-segment-input" type="radio" value="" name="segment" />
                                <span class="form-segment-label">選択肢1</span>
                              </label>
                            </li>
                            <li class="form-segment-item">
                              <label class="form-segment-set">
                                <input class="form-segment-input" type="radio" value="" name="segment" checked />
                                <span class="form-segment-label">選択肢2</span>
                              </label>
                            </li>
                            <li class="form-segment-item">
                              <label class="form-segment-set">
                                <input class="form-segment-input" type="radio" value="" name="segment" />
                                <span class="form-segment-label">選択肢3</span>
                              </label>
                            </li>
                            <li class="form-segment-item">
                              <label class="form-segment-set">
                                <input class="form-segment-input" type="radio" value="" name="segment" />
                                <span class="form-segment-label">選択肢4</span>
                              </label>
                            </li>
                            <li class="form-segment-item">
                              <label class="form-segment-set">
                                <input class="form-segment-input" type="radio" value="" name="segment" disabled />
                                <span class="form-segment-label">disabled</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                    <!--[datepicker]-->
                    <FormRow>
                      <template v-slot:label>DATEPICKER</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-icon form-date">
                            <Flatpickr class="form-control form-icon-input form-date-input" name="" v-model="date" :config="config" />
                          </div>
                        </div>
                        <div class="form-content-row">
                          <div class="form-icon form-date">
                            <input class="form-control form-icon-input form-date-input js-datepicker" type="text" name="" disabled />
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <!--[group]-->
                    <FormRow>
                      <template v-slot:label>GROUP</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">前</div>
                            <div class="form-group-item">
                              <input class="form-control" type="text" name="" />
                            </div>
                            <div class="form-group-item">うしろ</div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-red" type="button" v-on:click="alertSampleError">Alert(error)</button>
          </li>
          <li class="listGrid-item pos-end">
            <button class="btn btn-main" type="button" v-on:click="alertSampleSuccess">Alert(success)</button>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Notification />
  </div>
</template>

<script>
import nav from '@/mixins/nav/sample';
import NumDisplay from '@/components/NumDisplay.vue';
import FormRow from '@/components/FormRow.vue';
import Notification from '@/components/Notification.vue';
//file(image)upload
import Fileupload from '@/components/Fileupload.vue';
//modal
import Modal from '@/components/Modal.vue';
import modal from '@/mixins/plugin/modal';
//flatpickr
//https://github.com/flatpickr/flatpickr
//https://www.npmjs.com/package/vue-flatpickr-component
import Flatpickr from 'vue-flatpickr-component';
import flatpickr from '@/mixins/plugin/flatpickr';
//chart
//https://vue-chartjs.org/
//https://www.chartjs.org/
import ChartDoughnut from '@/components/ChartDoughnut.vue';
//tooltip
import Tooltip from '@/components/Tooltip.vue';

export default {
  name: 'Module',
  data: function() {
    return {
      pageName: 'モジュール',
      commaSample01: 200000000,
      date: '',
      //tableData
      tableSample: [
        {
          name: '榎本 花蓮',
          tel: '090-2772-1524',
          mail: 'morihiko506@sdatkurep.zsb',
          date: 'yyyy/mm/dd hh:mm',
          checked: true,
        },
        {
          name: '中川 孝子',
          tel: '080-1768-9098',
          mail: 'kyara337@qhchxi.hx',
          date: 'yyyy/mm/dd hh:mm',
          checked: true,
        },
        {
          name: '古橋 敏明',
          tel: '080-1488-7402',
          mail: 'masami27059@pkdstyldb.qzw',
          date: 'yyyy/mm/dd hh:mm',
          checked: true,
        },
        {
          name: '柿崎 真緒',
          tel: '090-9732-9927',
          mail: 'norikokurosawa@soccndfhgx.nco',
          date: 'yyyy/mm/dd hh:mm',
          checked: false,
        },
        {
          name: '広川 理央',
          tel: '080-9376-1873',
          mail: 'ule=rishiyori07680@jeiachcixo.klf.xr',
          date: 'yyyy/mm/dd hh:mm',
          checked: false,
        },
        {
          name: '篠崎 光昭',
          tel: '090-6332-4637',
          mail: 'oobata@yhhzgjca.zi',
          date: 'yyyy/mm/dd hh:mm',
          checked: true,
        },
      ],
      //chartData
      chart01: {
        labels: ['Red', 'Yellow', 'Green', 'Blue', 'Purple'],
        datasets: [
          {
            data: [12, 3, 22, 5, 2],
            backgroundColor: ['rgb(255, 128, 139)', 'rgb(239, 199, 32)', 'rgb(65, 219, 172)', 'rgb(94, 129, 244)', 'rgb(147, 102, 219)'],
            hoverBackgroundColor: ['rgb(255, 128, 139)', 'rgb(239, 199, 32)', 'rgb(65, 219, 172)', 'rgb(94, 129, 244)', 'rgb(147, 102, 219)'],
            borderColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 1,
          },
        ],
      },
      chart01options: {
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          labels: {
            boxWidth: 12,
            fontColor: 'rgb(79, 78, 105)',
          },
        },
      },
      chart02: {
        labels: ['Red', 'Blue', 'Gray'],
        datasets: [
          {
            data: [32, 47, 21],
            backgroundColor: ['rgb(255, 128, 139)', 'rgb(94, 129, 244)', 'rgb(178, 183, 210)'],
            hoverBackgroundColor: ['rgb(255, 128, 139)', 'rgb(94, 129, 244)', 'rgb(178, 183, 210)'],
            borderColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 1,
          },
        ],
      },
      chart02options: {
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          labels: {
            boxWidth: 12,
            fontColor: 'rgb(79, 78, 105)',
          },
        },
      },
    };
  },
  components: {
    FormRow,
    NumDisplay,
    Modal,
    Fileupload,
    Flatpickr,
    ChartDoughnut,
    Tooltip,
    Notification,
  },
  methods: {
    alertSampleSuccess: function() {
      this.$store.dispatch('ADD_ALERTS', {
        message: 'success message',
        type: 'success',
      });
    },
    alertSampleError: function() {
      this.$store.dispatch('ADD_ALERTS', {
        message: 'error message',
        type: 'error',
      });
    },
  },
  mixins: [nav, modal, flatpickr],
};
</script>
